<template>
    <el-row>
        <el-col :span="18">
            <div class='charts' ref='charts' style='width:700px; height: calc( 100vh - 100px);'></div>
        </el-col>
        <el-col :span="6" style='height: calc( 100vh - 100px);display: flex; flex-direction: column; align-items: center;'>
            <div style="display: flex; width: 100%;margin-bottom: 20px;">
              <el-radio-group v-model="radio" size="small" @change="radioChange" style="display: flex;flex-direction: row">
                <el-radio label="1" border>十六方位风向</el-radio>
                <div style="display: flex;flex-direction: column;">
                  <el-radio label="2" border>八方位风向</el-radio>
                  <el-checkbox v-model="checked" style="text-align: center;padding-top: 10px;" @change="checkboxChange">中文</el-checkbox>
                </div>
              </el-radio-group>
            </div>
            <el-card>
              <div slot="header" class="clearfix">
                <span>度数转换风向符号</span>
              </div>
                <div style="height: 160px; display: flex; flex-direction:column;align-items: center; justify-content: space-around">
                  <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item label="度数：" prop="input" :rules="[{ required: true, message: '方位角度数不能为空'},
                                  { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确保留两位小数' }]">
                      <el-input v-model="form.input" placeholder="请输入方位角度数值" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="符号：">
                      <span style="text-align: left">{{ result }}</span>
                    </el-form-item>
                      <el-form-item>
                          <el-button type="primary" size="medium " @click="okClick('form')">转 换</el-button>
                      </el-form-item>
                  </el-form>
                </div>
            </el-card>
          <div style="width: 100%;margin-top: 20px;">
            <el-card>
              <div slot="header" class="clearfix">
                <span>导入数据转换</span>
              </div>
              <div style="height: 120px; display: flex; flex-direction:column;align-items: center;">
                <dvi style="padding-bottom: 20px">表头必须包含“风向”字段</dvi>
                <div><input type="file" @change="handleFileUpload" /></div>

              </div>
            </el-card>
          </div>

        </el-col>
      <el-dialog :visible.sync="dialogVisible" >
        <div slot="title" style="display: flex;justify-content: space-between">
          <span>转换结果</span>

          <div style="width: 120px;display: flex"> <el-button type="plan" @click="exportTable">导出表格</el-button></div>
        </div>
        <el-table :data="myBook" border >

                    <el-table-column
                        v-for="(item, index) in keys"
                        :key="index"
                        :prop="item"

                        :label="item">
                    </el-table-column>
        </el-table>
        <div v-for="item in keys1">{{item}}</div>
      </el-dialog>
    </el-row>

</template>
<script>
    import * as echarts from 'echarts'
    import XLSX from 'xlsx';    // npm install xlsx@0.15.3 注意安装版本！
    export default {
        name: 'wind',
        data() {
          return {
            // 子组件的表头数据
            dialogVisible: false,
            keys: [],
            keys1: [],
            myCharts: null,
            result: '',
            radio: '1',
            checked: false,
            rate: 11.25,  // 每分的一半
            windDirection8: [1, 0, 0, 0, 0, 0, 0, 0], // 8方位
            windString8: ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'],
            windString81: ['北', '东北', '东', '东南', '南', '西南', '西', '西北'],
            windString82: ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'],
            windDirection16: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 16方位
            windString16: ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'],
            windDirection: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 16方位
            windString: ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'],
              form:{
                input: '0'
              },
            myBook: [],
          }
        },
        mounted() {
            // 初始化柱状图
            this.setCharts()
            window.addEventListener('resize', () => {
                //图表自适应
                this.myCharts.resize()
            })
        },
        methods: {
          checkboxChange(){
            this.windString8 = this.checked ? this.windString81 : this.windString82
            console.log(this.windString8)
            this.windString = this.windString8
            // 重新渲染
            this.setCharts()
          },
          handleFileUpload(event) {
            const file = event.target.files[0];
            this.parseExcelData(file);
          },
          parseExcelData(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
              const data = new Uint8Array(e.target.result);
              let workBook = XLSX.read(data, { type: 'array' });
              // 处理工作簿数据...
              let workSheet = workBook.Sheets[workBook.SheetNames[0]]
              let myData = XLSX.utils.sheet_to_json(workSheet)
              console.log(myData)//这里已经能拿到转换后的json
              //const obj = { key1: 'value1', key2: 'value2', key3: 'value3' };
              var keys = [];
              for (let key in myData[0]) {  // 取得了表头的字段
                keys.push(key);
              }
              console.log(keys.includes('风向'))
              if(keys.includes('风向')){
                this.dialogVisible=true
                var j=0
                  myData.map((it)=>{
                   j = Math.floor((parseFloat(it['风向'])+parseFloat(this.rate))/parseFloat(this.rate*2))
                  it['风向']=this.windString[j]
                })
                this.myBook = myData
console.log(this.myBook)
//this.exportTable()
              }else{
                alert("导入的表格文件有错！请先检查。")
              }
              this.keys=keys
              // this.myBook = myData
            };

            reader.readAsArrayBuffer(file);
          },
          // 导出电子表格
          exportTable() {
            var fileName = "转换结果"
            //1. 新建一个工作簿
            let workbook = XLSX.utils.book_new();
            //2.2 把json对象转成工作表
            let sheet1 = XLSX.utils.json_to_sheet(this.myBook);
            //3.在工作簿中添加工作表
            XLSX.utils.book_append_sheet(workbook, sheet1, fileName); //工作簿名称
            //4.输出工作表,由文件名决定的输出格式
            XLSX.writeFile(workbook, fileName + '.xlsx'); // 保存的文件名
          },


          radioChange(){
            //alert(this.radio)
            this.rate = this.radio ==='1' ? 11.25 : 22.5
                this.windDirection = this.radio ==='1' ? this.windDirection16 : this.windDirection8
            this.windString = this.radio ==='1' ? this.windString16 : this.windString8
            // 重新渲染
            this.setCharts()
            this.result = ''
          },
            okClick(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        //alert('submit!');
                        if(this.form.input > 360 || this.form.input < 0){
                            this.form.input=''
                        }else{
                            this.windDirection =  Array(16).fill(0);
                            console.log(this.windDirection)
                            if(this.form.input < this.rate || this.form.input > (360-this.rate)){
                                this.windDirection[0]=1
                                this.result=this.windString[0]
                            }else{//获取该角度所在方位序号，1 2 3 4 5 6 7 8。。。
                              var i = Math.floor((parseFloat(this.form.input)+parseFloat(this.rate))/parseFloat(this.rate*2))
                                this.windDirection[i]=1
                                this.result=this.windString[i]
                            }
                            let windOption = {
                                series: [
                                    {
                                        type: 'bar',
                                        data: this.windDirection,
                                        coordinateSystem: 'polar',
                                        name: 'wind',
                                        stack: 'a',
                                        itemStyle: {
                                            normal: {
                                                color: 'orange'
                                            }
                                        },
                                        radius: ['40%', '70%']
                                    }
                                ],
                            }
                            this.myCharts.setOption(windOption)
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            // 展示折线图
            setCharts() {
                // 初始化实例
                this.myCharts = echarts.init(this.$refs.charts)
                let windOption = {
                    title: {
                        text: '十六方位风向示意图',
                        // subtext: '数据',
                        x: 'center'
                    },
                    angleAxis: {//示意图
                        type: 'category',
                        data: this.windString,
                        z: 0,
                        boundaryGap: false,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#ddd',
                                type: [9, 10], //'solid'
                            }
                        },
                        axisLine: {
                            show: false
                        }
                    },
                    radiusAxis: {
                        center: ['50%', '50%']
                    },
                    grid: {
                        // 控制图的大小，调整下面这些值就可以，
                        x: 40,
                        x2: 100,
                        y2: 150 // y2可以控制 X轴跟Zoom控件之间的间隔，避免以为倾斜后造成 label重叠到zoom上
                    },
                    polar: {},
                    series: [
                        {
                            type: 'bar',
                            data: [1], //Array(16).fill(0),
                            coordinateSystem: 'polar',
                            name: 'wind',
                            stack: 'a',
                            itemStyle: {
                                normal: {
                                    color: 'rgb(228, 211, 84)'
                                }
                            }
                        }
                    ],
                };
                this.myCharts.setOption(windOption)
            },
        }
    }
</script>
<style>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    input[type="number"]{
        -moz-appearance: textfield;
    }
</style>